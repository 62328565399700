<script setup>
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()
</script>

<template>
<div class="container pt-5">
    <h2 class="title mb-4">Pricing that meets everyone needs</h2>
    <p style="font-size: 1.2rem;">From threat intelligence to attack surface discovery, asset management, and brand protection</p>

    <h2 class="title mb-4">Ready made databases for custom deployments and offline usage</h2>
    <p style="font-size: 1.2rem;">From threat intelligence to attack surface discovery, asset management, and brand protection</p>
</div>

</template>

<style scoped>

</style>

