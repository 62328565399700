<script setup>
import { useUserStore } from '@/stores/user'
import { useRouter, useRoute} from 'vue-router'

import { useQueryStore } from '@/stores/query'
let queryStore = useQueryStore()

const userStore = useUserStore()
const router = useRouter()
const route = useRoute()

async function logout() {
  await userStore.logout();
  return router.push({ path:"/" })
}

function clear_query() {
  queryStore.result = null
  queryStore.error = ""
  return true
}
</script>

<template>
    <div class="d-flex" style="width: 100%;">
    <nav id="sidebar">
      <ul class="navbar-nav mb-auto" ondragstart="return false;">
        <li class="brand">
          <router-link class="sidebar-nav brand-img" to="/">
            <img src="~@/assets/webscout.svg" alt="" width="130">
          </router-link>
        </li>
        <li>
          <router-link class="sidebar-nav" :class="{ 'router-link-exact-active': route.path == '/' }" to="/">
            <font-awesome-icon class="me-2" :icon="['fas', 'chart-line']" />
            dashboard
          </router-link>
        </li>
        <li>
          <router-link class="sidebar-nav"  to="/query" @click.prevent="clear_query">
            <font-awesome-icon class="me-2" :icon="['fas', 'search']" />
            query
          </router-link>
        </li>
        <!--
        <li>
          <router-link class="sidebar-nav nav-link" to="/streams">
            <font-awesome-icon class="me-2" :icon="['fas', 'water']" />
            Streams
          </router-link>
        </li>
        <li>
          <router-link class="sidebar-nav nav-link" to="/feeds">
            <font-awesome-icon class="me-2" :icon="['fas', 'download']" />
            Downloads
          </router-link>
        </li>
        -->
      </ul> 
      <ul class="navbar-nav">
        <li>
          <router-link class="sidebar-nav nav-link" to="/plans">
            <font-awesome-icon class="me-2" :icon="['fas', 'chart-simple']" />
            plans
          </router-link>
        </li>
        <li>
          <router-link class="sidebar-nav nav-link" to="/docs/api">
            <font-awesome-icon class="me-2" :icon="['fas', 'code']" />
            api documentation
          </router-link>
        </li>
        <li>
          <router-link class="sidebar-nav nav-link" to="/docs/wiki">
            <font-awesome-icon class="me-2" :icon="['fas', 'archive']" />
            wiki
          </router-link>
        </li>
        <li>
          <router-link class="sidebar-nav nav-link" to="/account">
            <font-awesome-icon class="me-2" :icon="['fas', 'user-astronaut']" />
            {{ userStore.email }}
          </router-link>
        </li>
        <li @click="logout()" class="sidebar-nav" style="color: #ca5050; font-weight: bold;">
            <font-awesome-icon class="me-2" :icon="['fas', 'lock']" />
            logout
        </li>
      </ul> 
    </nav>
    </div>
</template>
<style scoped>
#sidebar {
  z-index: 10;
  padding: 0 0 1.6rem 0; 
  background: #151a22;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.brand {
  background-color: #151a22;
  min-height: 60px;
  border-right: 1px solid #191f27;
  border-bottom: 1px solid #191f27;
}

#sidebar .sidebar-nav {
  padding: 1rem 2rem; 
  color: #D0D5DC;
  transition: all .2s ease;
  font-weight: 300;
  display: block;
}

#sidebar .sidebar-nav:not(.brand-img) {
  border-bottom: 1px solid #191f27;
  border-right: 1px solid #191f27;
}



#sidebar .sidebar-nav:hover {
  color: white; 
  cursor: pointer;
  background-color: #161c24;
}

.router-link-active {
  font-weight: 500 !important;
  color: white !important;
}

.router-link-exact-active {
  font-weight: 500 !important;
  color: white !important;
}
</style>
