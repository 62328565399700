<script setup>
import {defineProps, watch, defineEmits, onMounted, ref, nextTick} from 'vue'

import RequestRunner from '@/components/modals/RequestRunner.vue'
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()

const API_URL = process.env.VUE_APP_API_URL;

const copyfield = ref(null)

const props = defineProps({
  name: String,
  query: String,
  method: String,
  body: String,
})

function run() {
   mainStore.show_modal(RequestRunner, { query: props.query, name: props.name, method: props.method, body: props.body}) 
}

function copy() {
  let url = API_URL + props.query
  
  if (url.includes("?")) {
    url = url + "&apikey={YOUR_APIKEY}"
  } else {
    url = url + "?apikey={YOUR_APIKEY}"
  }

  navigator.clipboard.writeText(url)
  copyfield.value.innerText = "Copied"
  setTimeout(() => {
    copyfield.value.innerText = "Copy"
  }, 600);
}


</script>

<template>
  <div class="runner">
    <div class="d-flex vertically-centered"> 
      <code style="flex:1; color: white;">{{ API_URL + props.query }}</code>
      <div class="box dark small-padded clickable" ref="copyfield" style="display: inline-block; align-self: start;" @click="copy()">
        Copy
      </div>
    </div>
    <hr>
    <div>
      <span class="runquery" style="" @click="run()">
        Run query
      </span>
    </div>
  </div>
</template>

<style scoped>
.runner {
  background-color: #1F2631;
  border-radius: 10px;
  padding: 1rem;
}

.runquery {
  display: inline-block;
  padding: .4rem 1.1rem;
  border-radius: 10px;
  background-color: #5094ed;
  color: white;
}

.runquery:hover {
  background-color: #4c82c8;
  cursor: pointer;
}

</style>
