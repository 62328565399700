<script setup>
import sidebar from '../../components/Sidebar.vue';
import querybar from '../../components/Query/SearchBar.vue';
import DropZone from '../../components/DropZone.vue'
import qf from '@/components/modals/QueryFromFile.vue'
import { useMainStore } from '@/stores/main'
import { onMounted, ref, onUnmounted, computed } from 'vue'
import { useQueryStore } from '@/stores/query'
import { useRouter, useRoute} from 'vue-router'
const router = useRouter()
let queryStore = useQueryStore()

import { useUserStore } from '@/stores/user'
const userStore = useUserStore()

import FileAnalyser from '@/lib/file_analyzer/analyzer.js'

const mainStore = useMainStore()


const file_name = ref("logs.txt") 
const analyzing_file = ref(false)
const active = ref(false)
const analyzing_percentage = ref(0)

const line1 = ref(null)

const path_shown = computed(() => {
  return path_length.value - (path_length.value * analyzing_percentage.value) 
})

const path_length = computed(() => {
  if (line1.value == null) return ''
  let total = line1.value.getTotalLength() 
  
  let boundingClient=line1.value.getBoundingClientRect();

  total = ((boundingClient.width/230)*2*200) + ((boundingClient.height/200)*2*230) 

  console.log(total)
  //total = total * line1.value.getBoundingClientRect().width/line1.value.getBBox().width
  return 7065
})

function onDrop(e) {
  console.log(e.dataTransfer.files)
  setAnalyzing()
  readFile(e.dataTransfer.files[0])
  //setInactive()
  //showOptions()
}

async function logout() {
  await userStore.logout();
  return router.push({ path:"/" })
}

function readFile(file) {
  const reader = new FileReader();
  reader.addEventListener('load', (event) => {
    const result = event.target.result;
    // Do something with result
  

    let res = FileAnalyser.analyze(result)

    mainStore.show_modal(qf, { fileName: file.name, domains: res.domains, ips: res.ips})
    console.log(res)

    setInactive()
  });

  reader.addEventListener('progress', (event) => {
    if (event.loaded && event.total) {
      const percent = (event.loaded / event.total)
      analyzing_percentage.value = percent
    }
  });
  reader.readAsText(file);
}

function preventDefaults(e) {
    e.preventDefault()
}


const events = ['dragenter', 'dragover', 'dragleave', 'drop']


function showOptions() {}

function setAnalyzing() {
  analyzing_file.value = true 
}

function setActive() {
  active.value = true
}

function setInactive() {
  analyzing_file.value = false
  analyzing_percentage.value = 0
  active.value = false
}

onUnmounted(() => {
  events.forEach((eventName) => {
      document.body.removeEventListener(eventName, preventDefaults)
  })
})

onMounted(() => {
  events.forEach((eventName) => {
      document.body.addEventListener(eventName, preventDefaults)
  })
})
</script>

<template>
  <div class="wrapper" @dragenter.prevent="setActive" @dragover.prevent="setActive" @dragleave.prevent="setInactive" @drop.prevent="onDrop"> 
    <div class="dropzone block-outer" :class="{ 'active': active, 'analyzing': analyzing_file }">
      <div class="main_block">
        <svg viewBox="0 0 200 200" v-if="analyzing_file"  preserveAspectRatio="none">
            <path  d="M 0 0 L 200 0 L 200 200 L 0 200 z" ref="line1" id="rectangle" fill="none" :style="{'stroke-dasharray':  path_length, 'stroke-dashoffset': path_shown }" stroke="white" stroke-width="30" vector-effect="non-scaling-stroke"  />
        </svg>

        <div class="d-flex column centered">
        <h3 class="title" style="color: #cdd4df" v-if="analyzing_file">Processing <span style="color: white">{{file_name}}</span>...</h3>
        <h3 class="title" style="color: #cdd4df" v-else>Release file to analyze</h3>
        <div v-if="analyzing_file">
          <span style="font-size: 2rem;">{{ analyzing_percentage }} %</span>
        </div>
        </div>
      </div>
    </div>

    <sidebar style="width: 280px;"></sidebar> 

    <div class="d-flex" style="flex: 1; flex-direction: column; max-width: calc(100% - 280px)">
      <querybar/>
      <div class="wrapper-content"  style="display:flex; justify-content: center; position: relative;" tabindex="0">
        <div class="loading_query" v-if="queryStore.running">
          <div>
            <div class="d-flex column centered pt-5" style="height: 100%;">
              <font-awesome-icon style="font-size: 7rem;" class="mb-4" :icon="['fas', 'circle-notch']" spin />
              <div class="d-flex centered column">
                <h4 class="text-center">Running query</h4>
              </div>
            </div>
          </div>
        </div>
        <router-view class="content"></router-view>
      </div>
    </div>
    <div class="hide-desktop mobile-popup">
      <div class="d-flex column vertically-centered" style="height: 100%;">
        <h3 class="text-center mt-5">We are sorry!</h3>
        <h1 class="text-center mt-5 mb-5">Webscout is made for larger screens</h1>
        <p class="text-center">To get the best experience switch to your laptop or desktop computer</p>

        <div class="box dark med-padded clickable mt-2" style="color: #ca5050; font-weight: bold; display: block;" @click="logout()">
          <font-awesome-icon class="me-3" :icon="['fas', 'lock']"/><strong>Logout</strong>
        </div>
      </div>

    </div>
  </div> <!-- end body --> 
</template>

<style>
.loading_query {
  position: absolute;
  z-index: 10;
  color: white;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  background-color: #191f27a6;
}

.dropzone.active {
  position: fixed; 
  left: 0; 
  top: 0; 
  bottom: 0; 
  right: 0;
  background: #191f27a6;
  z-index: 11;
}

.dropzone.active .main_block {
  display: flex;
}

.main_block {
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: 
        linear-gradient(90deg, #38434f 50%, transparent 0) repeat-x,
        linear-gradient(90deg, #38434f 50%, transparent 0) repeat-x,
        linear-gradient(0deg,  #38434f 50%, transparent 0) repeat-y,
        linear-gradient(0deg,  #38434f 50%, transparent 0) repeat-y;
  background-size: 30px 8px, 30px 8px, 8px 30px, 8px 30px;
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  z-index: 12;
}

.dropzone.analyzing .main_block svg {
  position: absolute;
  top: 0;
  left: 0; 
  width: 100%;
  height: 100%;

  /*
  --c: #8A9B0F; 
  --b: 10px;    
  --g: 5px;     
  
  padding: calc(var(--g) + var(--b));
  --_g: #0000 25%,var(--c) 0;
  background: 
    conic-gradient(from 180deg at top    var(--b) right var(--b),var(--_g))
     var(--_i,200%) 0  /200% var(--_i,var(--b))  no-repeat,
    conic-gradient(            at bottom var(--b) left  var(--b),var(--_g))
     0   var(--_i,200%)/var(--_i,var(--b)) 200%  no-repeat;
  transition: .3s, background-position .3s .3s;
  */
}

svg path {

}

.dropzone.analyzing .main_block {
  /*
  --_i: 100%;
  transition: .3s, background-size .3s .3s;
  */
  background: none;
}


.dropzone.active:not(.analyzing) .main_block {
    animation: linearGradientMove .3s infinite linear;
}

@keyframes linearGradientMove {
    100% {
        background-position: 30px 0, -30px 100%, 0 -30px, 100% 30px;
    }
}

.mobile-popup {
  position: fixed;
  z-index: 10;
  left: .5rem;
  top: .5rem;
  bottom: .5rem; 
  right: .5rem;
  padding: 1rem;
  border-radius: 10px; 
  background-color: #191f27;
}

</style>
