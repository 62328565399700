<script setup>
import {defineProps, computed, watch, defineEmits, onMounted, ref, nextTick} from 'vue'
import Jumbo from '@/components/Docs/Jumbo.vue'
import DocSection from '@/components/Docs/Section.vue'
import CodeBlock from '@/components/Docs/CodeBlock.vue'
import JsonBlock from '@/components/Docs/JsonBlock.vue'


import StringParam from '@/components/Docs/Parameters/StringParam.vue'
import RequestRunner from '@/components/Docs/RequestRunner.vue'


import BasicAttribute from '@/components/Docs/Attributes/BasicAttribute.vue'

let request_ip = ref("89.36.76.126")

let get_query = computed(() => {
  return "/query/ip/" + request_ip.value + "" 
}) 

</script>

<template>
  <div>
    <doc-section title="Ip information">
      <template v-slot:left>
        <div class="mb-5">
        This endpoint returns a collection of general information on an IP address. <br><br>
        Note that the returned information may vary depending on the plan that you are currently on. For instance provider and operator information requires the Basic plan, and behavioral information require the Professional plan.
        </div>
      
        <div class="mb-5">
          <h4 class="mb-4">Request parameters</h4>

          <StringParam class="mb-3" name="IP address" :required="true" description="The ip address you wish to know about" type="string" :value="request_ip" @value="(v) => request_ip = v" />
          <RequestRunner class="mt-5" :query="get_query" name="Ip information" method="GET"></RequestRunner>
        </div>

        <div>
          <h4 class="mb-4">Response attributes</h4>

          <BasicAttribute name="status" type="string" description="The status of the request's response" />
          <hr>

          <BasicAttribute name="data" type="object" description="The requested information">

            <BasicAttribute name="ip" type="string" description="The target IP address" />
            <hr>

            <BasicAttribute name="location" type="object" description="The location of the ip">
              <BasicAttribute name="country_iso" type="string" description="Two letter country code" />
            </BasicAttribute>
            <hr>

            <BasicAttribute name="domains_on_ip" type="integer" description="The number of domains resolving to the IP" />
            <hr>

            <BasicAttribute name="operator" type="string" description="The entity using the ip or machine behind it" />
            <hr>

            <BasicAttribute name="network_type" type="string" description="The likely type of infrastructure used to operate the ip" />
            <hr>

            <BasicAttribute name="network_provider" type="string" description="The entity managing the ip address" />
            <hr>

            <BasicAttribute name="network_provider_services" type="string array" description="A list of services the managing entity provides" />
            <hr>

            <BasicAttribute name="behavior" type="string array" description="Activity assosiated with the IP address" />
            <hr>

            <BasicAttribute name="open_ports" type="integer array" description="Open network ports on the IP. This can be used to assertain it's use" />
            <hr>

            <BasicAttribute name="as_org" type="string" description="The name of the organization owning the ip address allocation" />
            <hr>

            <BasicAttribute name="asn" type="integer" description="The AS number of the organization owning the ip address allocation" />
            <hr>

            <BasicAttribute name="avg_ttl" type="integer" description="Average time-to-live (ttl) of packets coming from the IP address" />
            <hr>

            <BasicAttribute name="operator_description" type="string" description="A brief description of who the operator is" />
            <hr>

            <BasicAttribute name="provider_description" type="string" description="A brief description of who the network provider is" />
            <hr>

            <BasicAttribute name="os_guess" type="integer" description="A guess on the operating sytem of the machine, handling traffic going to the IP" />

          </BasicAttribute>


        </div>

      </template>

      <template v-slot:right>
        <code-block title="/query/ip/:ip" method="GET" :dark="true"/>
      
        <json-block title="Response">
{
"status": "success",
"data": {
"ip": "89.36.76.126",
"location": {
"country_iso": "RO"
},
"domains_on_ip": 1,
"operator": "Surfshark VPN",
"network_type": "Cloud",
"network_provider": "M247",
"network_provider_services": [
"",
"Backup/Recovery",
"Business Internet",
"Cloud",
"Colocation",
"Hosting",
"VPC"
],
"behavior": null,
"open_ports": [
7443,
8443
],
"as_org": "M247 Europe SRL",
"asn": 9009,
"avg_ttl": 53,
"provider_description": "An internet service provider offering a range of services including broadband connectivity, data center solutions, and cloud services.",
"operator_description": "",
"os_guess": "linux"
}
}
        </json-block>
      </template>
    </doc-section>
  </div>
</template>

<style scoped>
</style>
