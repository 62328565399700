<script setup>
import {defineProps} from 'vue'
const props = defineProps(["data"])

import moment from 'moment'

let items = {}

if (props.data != null) {
  for (let i=0; i<props.data.length; i++) {
    let parts = props.data[i].split("_")
    
    // its a main tag
    if (parts.length == 1) {
      if (parts[0] in items) {
        continue
      }

      items[parts[0]] = {
        sub_items: [] 
      }
    // it is a subtag
    } else if (parts.length > 1) {
      let main_tag = parts[0]
      if (!(main_tag in items)) {
        items[main_tag] = {
          sub_items: [] 
        }
      }
      items[main_tag].sub_items.push(parts.join("_"))
    }
  }
}
</script>

<template>

  <div>
    <div v-if="props.data != null && props.data.length > 0" class="d-flex vertically-centered" >
      <div v-for="it, tag in items">
        <div v-if="it.sub_items.length > 1" style="position: relative;" class="tag dropdown dropdown-toggle" data-bs-toggle="dropdown" >
          <span >{{tag}} +{{it.sub_items.length}}</span>
          <ul class="dropdown-menu">
            <li v-for="st in it.sub_items" >{{st}}</li>
          </ul>
        </div>
        <div v-else-if="it.sub_items.length == 1" class="tag">
          <span >{{it.sub_items[0]}}</span>
        </div>
        <span v-else class="tag">
          <span >{{tag}}</span>
        </span>
      </div>

    </div>
  </div>
</template>

<style scoped>
.dropdown-menu li {
  color:white  !important;;
  padding: 0 .5rem;
} 
.dropdown-menu {
  background-color: #151a22 !important;
}

.tag {
  position: relative;
  border: 1px solid #616B79;
  border-radius: 20px;
  font-size: .9rem;
  padding: 0 .5rem;
  margin-right: .1rem;
}
</style>
