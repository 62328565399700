<script setup>
import { onMounted, ref, computed } from 'vue'
import Feeds from '../../api/Feeds';
import Slideover from '../../components/SlideOver/slideover.vue'
import FeedComp from '../../components/SlideOver/components/Feed.vue'
import FeedForm from '../../components/SlideOver/forms/CreateFeed.vue'
import StandardTable from '../../components/Table/Standard.vue'
import { useUserStore } from '@/stores/user'
import moment from 'moment'
import { useMainStore } from '@/stores/main'
let mainStore = useMainStore()

import Databases from '@/data/databases.js'

const userStore = useUserStore()
const feeds = ref([])

onMounted(async () => {
  feeds.value = (await Feeds.featured()).data
})
</script>

<template>
  <div style="min-height: 100%;" class="p-3">
    
    <div class="">
      <div class="box mb-3 med-padded d-flex vertically-centered">
        <font-awesome-icon style="color: rgb(146, 139, 232); font-size: 1.2rem;" class="me-4" :icon="['fas', 'database']" />
        <h5 class="mb-0">Databases</h5>
      </div>
     
      <div class="d-flex">

        <!-- <h1 class="display-5 title centered mb-5">Transparent pricing</h1> -->
        <div class="box none padded me-3 d-flex column" v-for="plan in Databases" :key="plan" style="color: rgb(157, 165, 176); width: 25rem;">
          <div class="title mb-3">
            {{ plan.title }}
          </div>
          <div class="mb-4">
            <span class="badge rounded-pill text-bg-danger">access expires in: 234 days</span>
          </div>
          <div class="mb-5">
            <div class="mb-3 d-flex">
              <div class="field-name">
                Sha256 checksum: 
              </div>
            </div>
            <div class="mb-3 d-flex">
              <div class="field-name">
                Last updated: 
              </div>
            </div>
            <div class="mb-3 d-flex">
              <div class="field-name">
                File size: 
              </div>
            </div>
            <div class="mb-3 d-flex">
              <div class="field-name">
                Number of entries: 
              </div>
            </div>
            <div class="mb-3 d-flex">
              <div class="field-name text-bold">
                <router-link to="/docs/wiki">Format guide</router-link>
              </div>
            </div>
            <div class="mb-3 d-flex">
              <div class="field-name text-bold">
                <router-link to="/docs/wiki">Database guide</router-link>
              </div>
            </div>
          </div>
          <div class="text-center mt-auto">
            <div class="mb-2 d-flex">
              <select class="form-select me-2" aria-label="Default select example">
                <option selected>Select format</option>
                <option value="1">jsonl</option>
                <option value="2">csv</option>
                <option value="3">mmdb</option>
              </select>
              <button>
                <font-awesome-icon :icon="['fas', 'download']" />
              </button>
            </div>
          </div>
          <div>
          </div>
        </div>



      </div>
    </div>

    <!--
    <div class="mb-5">
      <div class="box mb-3 med-padded d-flex vertically-centered">
        <font-awesome-icon style="color: #e7f25c; font-size: 1.2rem;" class="me-4" :icon="['fas', 'square-rss']" />
        <h5 class="mb-0">Streams</h5>
      </div>
      
      <div class="d-flex">
        <div v-for="stream in Streams" class="me-3" :key="stream">
          <Stream :data="stream"/>
        </div> 
      </div>
    </div>

    <div class="">
      <div class="box mb-3 med-padded d-flex vertically-centered">
        <font-awesome-icon style="color: rgb(146, 139, 232); font-size: 1.2rem;" class="me-4" :icon="['fas', 'database']" />
        <h5 class="mb-0">Datasets</h5>
      </div>
     
      <div class="d-flex">
        <div v-for="database in Databases" class="me-3" :key="database">
          <Database :data="database"/>
        </div> 
      </div>
    </div>
    -->

  </div>
</template>

<style scoped>
.title {
  font-size: 1.5rem;
  color: white;
  font-weight: normal;
}

.price {
  font-size: 2rem;
  color: white;
}

.box.none {
  background: transparent;
  border: 2px solid #1e252f;
}

button {
  background-color: #1E252F;
  border-radius: 10px;
  border:none;
  margin-right: 5px;
  font-weight: bold;
  color: white;
  padding: .8rem 1.5rem;
}

.form-select {
  border-radius: 10px;
  cursor: pointer;
  padding-left: 1rem;
}

button.dark {
  background-color: #131820
}

button:hover {
  background-color: #2B3440;
}

button.disabled {
  background-color:  #171C24
}

button.disabled:hover {
  background-color:  #171C24;
  cursor: default;
}

.field-name {
  color: white;
}

.text-bold {
  font-weight: bold;
}
</style>
