export default
[
  {
    "id": "0000-0000-000-00000-00000",
    "title": "IP database",
    "checksum": "",
    "last_update": "",
    "file_size": "",
    "num_entries": "",
  },

  {
    "id": "0000-0000-000-00000-00000",
    "title": "Domain database",
    "checksum": "",
    "last_update": "",
    "file_size": "",
    "num_entries": "",
  },
]

